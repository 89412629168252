module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Macondo Games","short_name":"Macondo Games","start_url":"/","background_color":"#000","theme_color":"#000","display":"standalone","icon":"src/images/icon.png","icons":[{"src":"src/images/icon.png","sizes":"512x512","type":"image/png"},{"src":"src/images/maskable-icon.png","sizes":"192x192","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2ede0d59c231ba097b2db24f0aa58b36"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","de"],"defaultLanguage":"en","siteUrl":"https://www.macondogames.com","i18nextOptions":{"fallbackLng":"en","supportedLngs":["en","de"],"defaultNS":"common","interpolation":{"escapeValue":false}},"redirect":true,"pages":[{"matchPath":"/press","languages":["en"]},{"matchPath":"/dev-404-page","languages":["en"]}]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
